import React, { Component } from 'react';
import About from '../About/About'

class Header extends Component {
    render() {
        return (
            <div className="container mt-2">
                <div className="d-flex font-weight-bold">
                    <h4 className="mr-auto p-2">SOWAS</h4>
                    <div className="p-2">
                        <About />
                    </div>
                </div>
            </div>
        );
    }
}

export default Header;