import React, { Component } from 'react';
import Application from '../../assets/docs/application.docx'
class Criteria extends Component {
    render() {
        return (
            <div className="bg-light">
                <div className="container mt-5 pt-5 pb-3">
                    <div className="row ">
                        <div className="col-md-6">
                            <h4 className="mb-2">Scholarship Criteria</h4>
                            <ul className="text-muted pl-4 w-75">
                                <li>Must be an active member of a Church</li>
                                <li>Worker in the church in at least one capacity(i.e - instrumentalist, usher, cleaning crew etc)</li>
                                <li>High School Senior or Current College Student</li>
                                <li>Must be African or African American</li>
                                <li>Must be a NY Resident</li>
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <h4>Submission Requirements</h4>
                            <ul className="text-muted pl-4">
                                <li>Tell us what your current or intended major</li>
                                <li>Tell us about a great advice you've received from someone, the impact it had and how you've applied it to your life (300 - 500 words)</li>
                                <li>Attach reference from Pastoral/Ministry Head/ Youth Leader etc.</li>
                                <a type="button" className="btn btn-dark" href={Application}>Application Form</a>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Criteria;