import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }
    render() {
        return (
            <div>
                <button type="button" className="btn btn-dark" onClick={this.toggle}>Learn More</button>
                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                    <ModalHeader>Message from Founder</ModalHeader>
                    <ModalBody className="text-muted">
                        <span className="font-weight-bold">SOWAS</span>(Sow.A.Seed) was founded to contribute to students' college expenses. The thought is - wouldn't it be nice if someone in the church or the church can assist one of its students in going to school? <br /><br />SOWAS scholarship award helps make the difference between you getting private loan, parent plus loan etc., to make up for that really high tuition deposit, tuition balance, health insurance they required you to get or those books you had to purchase only from the school. Let's not talk about that expensive meal plan, ugh.
                        Or maybe you come from a family where you're not as fortunate to have the proper resource in any capacity to go to school. <br /> <br/>
                       <span className="font-weight-bold">If any of this resonates with you, then please apply for the scholarship. We look forward to hearing from you :).</span>
                  </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-dark" onClick={this.toggle}>Close</button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default About;