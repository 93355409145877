import React, { Component } from 'react';
import Submit from '../../assets/images/check-form.png';
import Award from '../../assets/images/award.png';
import Deadline from '../../assets/images/calendar.png';

class Overview extends Component {
    render() {
        return (
            <div className="container text-center pb-5 pt-3">
                <div className="row mb-2 pb-2">
                    <div className="col-md-1">
                        <img src={Submit} alt="submit" />
                    </div>
                    <div className="col-md-3">
                        <p className="mb-0 font-weight-bold">Submission</p>
                        <p className="text-muted">Email your application to <br/>sowasproject@gmail.com</p>
                    </div>
                    <div className="col-md-1">
                        <img src={Award} alt="award" />
                    </div>
                    <div className="col-md-3">
                        <p className="mb-0 font-weight-bold">Award Amount</p>
                        <p className="text-muted">$500</p>
                    </div>
                    <div className="col-md-1">
                        <img src={Deadline} alt="deadline" />
                    </div>
                    <div className="col-md-3">
                        <p className="mb-0 font-weight-bold">Application Deadline </p>
                        <p className="text-danger">July 26th, 2019</p>
                    </div>

                </div>
                <p className="mb-0 text-muted">**All finalist will be notified no later than August 9th, 2019**</p>
                <p className="text-muted">The 2nd and 3rd runner up will receive a prize.</p>
            </div>
        );
    }
}

export default Overview;