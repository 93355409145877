import React, { Component } from 'react';
import Header from '../Header/Header';
import About from '../Headline/Headline';
import Criteria from '../Criteria/Criteria';
import Overview from '../Overview/Overview';
import Footer from '../Footer/Footer'

class Page extends Component {
    render() {
        return (
            <div>
                <Header />
                <About />
                <Criteria />
                <Overview />
                <Footer />
            </div>

        );
    }
}

export default Page;