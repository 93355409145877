import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
            <div className="bg-light container">
                <div class="row  border-top text-center mb-3 pt-3 text-muted">
                <div className="col-md-4">sowasproject@gmail.com</div>
                <div className="col-md-4">Made with love by <a href="https://estherk-portfolio.netlify.com"><u>55Lab</u></a></div>
                <div className="col-md-4">Copyright ©2019</div>
            </div>
            </div>
        );
    }
}

export default Footer;