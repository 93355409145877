import React, { Component } from 'react';

class About extends Component {
    render() {
        return (
            <div className="container text-center">
                <h3 className="font-weight-bold mb-0 mt-5 brand_name">SOW.A.SEED</h3>
                <p className="text-muted font-weight-bold">Making the impact to assist students academic journey</p>
            </div>
        );
    }
}

export default About;